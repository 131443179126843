import React from "react";
import landingImage from "../assets/cover/homepage.jpg";

const Landing = ({isMobile})=>{
    return(
        <section className = "my-5 statement">
            <div className={`${!isMobile && 'flex'} statement-container`}>
            <div className="statement-img-container">
            <img src={landingImage} className={`${!isMobile && 'max-50 coverImage-margin'} cover-img`} alt="cover" />
            </div>
        </div>
        </section>
    )
}

export default Landing;