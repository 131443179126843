import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateEmail, capitalizeFirstLetter } from '../utils/helpers'
import Footer from "./Footer";

function ContactForm({faEnvelope, faFacebook, faInstagram, faTwitter}) {
    const [formState, setFormState] = useState({name: '', email: '', message: ''});
    const { name, email, message} = formState;
    const [errorMessage, setErrorMessage] = useState('');

  return (
    <section className="contact">
      <div>
        {/* <h1>Contact me</h1> */}
        <div className="contact-fa-container">
        {/*Facebook*/}
        <a href="https://www.facebook.com/lynn.carlin.5">
        <FontAwesomeIcon icon={faFacebook} className="fa-contact faFacebook"/>
        </a>
        {/* Twitter */}
        <FontAwesomeIcon icon={faTwitter} className="fa-contact faTwitter"/>
        {/* Email */}
        <FontAwesomeIcon icon={faEnvelope} className="fa-contact faEnvelope"
        onClick = {() => window.open('mailto:artistnarcata@gmail.com')}
        />
        {/* Instagram */}
        <a href="https://www.instagram.com/lynncarlin/">
        <FontAwesomeIcon icon={faInstagram} className="fa-contact faInstagram"/>
        </a>
        <footer className="contact-footer">
            <p>Lynn Carlin</p>
            <p
            className='pointer footer-email' 
            onClick = {() => window.open('mailto:artistnarcata@gmail.com')}
            >artistnarcata@gmail.com</p>
            <p className='AidanMeyer'> Website by Aidan Meyer</p>
        </footer>
        </div>
        
      </div>
      
    </section>
  );
}

export default ContactForm;
