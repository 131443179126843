import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Header ( { faX,
                    currentCategory,
                    setCurrentCategory,
                    galleryCategories, 
                    otherCategories, 
                    isMobile,
                    isNavOpen,
                    setIsNavOpen,
                    isGalleryOpen,
                    setIsGalleryOpen,
                    setContactSelected,
                    contactSelected,
                    setAboutSelected,
                    aboutSelected,
                    setIsLandingPage
                } ) {

    function toggleGalleryOpen () {
        setIsGalleryOpen(!isGalleryOpen);
    }

    function toggleIsNavOpen () {
        setIsNavOpen(!isNavOpen);
    }
    function checkSelection () {
        for (let i = 0; i < 2; i++) {
            if (currentCategory===galleryCategories[i]) {
                return true;
            }
        }
        return false;
    }
    return (
        <header id="header">
        <div>
            <div className='lynn-carlin-art-container'>
                <h1>Lynn Carlin Art</h1>
                <a href='#top'>
                    <FontAwesomeIcon icon={faX} onClick={()=>{toggleIsNavOpen()}}  className={`${!isNavOpen && 'display-none'} ${!isMobile && 'display-none'}`}></FontAwesomeIcon>
                </a>
            </div>
            <nav className={`nav-container ${!isNavOpen && isMobile && 'display-none'}`}>
                <ul className='nav-ul-container'>
                    <li onClick={()=>{toggleGalleryOpen()}}>
                        <div className={`
                        ${isGalleryOpen && 'selected-nav'}
                        ${checkSelection() && 'selected-nav'}
                        `}>Gallery ▾</div>
                        <ul className={`inner-nav-dropdown-container ${!isGalleryOpen && 'display-none'}`}>
                            <li onClick={()=> {
                                setCurrentCategory(galleryCategories[0])
                                setContactSelected(false)
                                setAboutSelected(false)
                                setIsLandingPage(false)

                                if (isMobile) {toggleIsNavOpen()}
                                }}
                                className={`${currentCategory===galleryCategories[0] && 'selected-nav'}`}
                            >
                                Paintings
                            </li>
                            <li onClick={()=>{
                                setCurrentCategory(galleryCategories[1])
                                setContactSelected(false)
                                setAboutSelected(false)
                                setIsLandingPage(false)
                                if (isMobile) {toggleIsNavOpen()}
                                }}
                                className={`${currentCategory===galleryCategories[1] && 'selected-nav'}`}
                            >
                                Mixed Media Collage
                            </li>
                            <li onClick={()=>{
                                setCurrentCategory(galleryCategories[2]);
                                setContactSelected(false)
                                setAboutSelected(false)
                                setIsLandingPage(false)
                                if (isMobile) {toggleIsNavOpen()}
                                }}
                                className={`${currentCategory===galleryCategories[2] && 'selected-nav'}`}
                            >
                                Children Playing
                            </li>
                        </ul>
                    </li>
                        <li 
                        onClick={()=>{
                            setCurrentCategory(otherCategories[0])
                            setContactSelected(false)
                            setAboutSelected(false)
                            setIsLandingPage(false)
                        }}
                        className={`${currentCategory===otherCategories[0] && 'selected-nav'}`}
                        >Select Past Works</li>
                        <li 
                        onClick={()=>{setCurrentCategory(otherCategories[1]) 
                        setContactSelected(false)
                        setAboutSelected(false)
                        setIsLandingPage(false)}}
                        className={`${currentCategory===otherCategories[1] && 'selected-nav'}`}
                        // >Prints And Cards</li>
                        >Prints</li>
                    <li onClick={()=>{
                        setContactSelected(false)
                        setAboutSelected(true)
                        setIsLandingPage(false)
                        setCurrentCategory()
                    }}
                    className={`${aboutSelected && 'selected-nav'}`}
                    >About</li>
                    <li onClick={()=> {
                        setContactSelected(true)
                        setAboutSelected(false)
                        setIsLandingPage(false)
                        setCurrentCategory()
                        }}
                        className={`${contactSelected && 'selected-nav'}`}
                        >Contact</li>
                </ul>
            </nav>
        </div>
        </header>
    );
}

export default Header;