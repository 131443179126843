import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Statement from './components/Statement';
import ContactForm from './components/Contact';
import Footer from './components/Footer';
import Landing from './components/Landing';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faAngleRight, faAngleLeft, faBars, faEnvelope, faF} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'


import Header from './components/Header';
import Portfolio from './components/Portfolio'




function App() {


  const [galleryCategories] = useState([
    {
      name: "Paintings", description: "description"
    },
    {
      name: "Mixed Media Collage", description: "description"
    },
    {
      name: "Children Playing", description: "description"
    }
]);
const [otherCategories] = useState([
  {
    name: "Select Past Work", description: "description"
  },
  {
    name: "Prints & Cards", description: "description"
}
]);

const [currentCategory, setCurrentCategory] = useState();
const [contactSelected, setContactSelected] = useState(false);
const [aboutSelected, setAboutSelected] = useState(false);
const [isNavOpen, setIsNavOpen] = useState(true);
const [isGalleryOpen, setIsGalleryOpen] = useState(false)
const [isModalOpen, setIsModalOpen] = useState(false);
const [isLandingPage, setIsLandingPage] = useState(true);

const isMobile = useMediaQuery({ query: `(max-width: 822px)` });


console.log("Website by Aidan Meyer https://xxlzopes.github.io/react-portfolio/");


  return (
    <div 
    className={`${!isModalOpen && 'root'}`}> 
    <div id="top"></div>
    {isMobile && <>
      <a href='#top'>
      <FontAwesomeIcon
      onClick={()=>{setIsNavOpen(true)}}
      className={`faBars ${isNavOpen && 'display-none'}`}
      icon={faBars}
      >
      </FontAwesomeIcon>
      </a>
    </>}
    {!isModalOpen &&
      <Header 
      faX = {faX}
      galleryCategories = {galleryCategories}
      otherCategories = {otherCategories}
      currentCategory = {currentCategory}
      setCurrentCategory = {setCurrentCategory}
      isMobile = {isMobile}
      isNavOpen = {isNavOpen}
      setIsNavOpen = {setIsNavOpen}
      isGalleryOpen = {isGalleryOpen}
      setIsGalleryOpen = {setIsGalleryOpen}
      setContactSelected = {setContactSelected}
      contactSelected={contactSelected}
      setAboutSelected = {setAboutSelected}
      aboutSelected={aboutSelected}
      setIsLandingPage = {setIsLandingPage}
      ></Header>
    }

    { 

      isLandingPage ? (
        <>
        <Landing></Landing>
        <Footer isLandingPage={isLandingPage}></Footer>
        </>
      ) :
    
      contactSelected ? (
      <ContactForm
      faEnvelope={faEnvelope}
      faFacebook={faFacebook}
      faInstagram={faInstagram}
      faTwitter={faTwitter}
      ></ContactForm>
    ) : aboutSelected ? (
      <>
      <Statement></Statement>
      {/* <Footer aboutSelected={aboutSelected}></Footer> */}
      </>
    ) : 
    <main>
      
      <Portfolio
      currentCategory = {currentCategory}
      faAngleRight = {faAngleRight}
      faAngleLeft = {faAngleLeft}
      faX = {faX}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      ></Portfolio>
      <Footer aboutSelected={aboutSelected}></Footer>
      </main>
    }
  
    </div>
  );
}

export default App;
