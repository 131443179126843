import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Modal({ onClose, currentPhoto, currentArtwork, faAngleRight, faAngleLeft, faX }) {
    let {name, category, index, medium, size, price} = currentPhoto;
    console.log(category);
    if (currentPhoto.index == undefined) {
        index = 0;
    }
    const [changingIndex, setIndex] = useState(index);
    return (
        <div className="modalBackdrop">
            <div className='faX-div'><FontAwesomeIcon icon={faX} onClick={onClose} className='faX'></FontAwesomeIcon></div>
            <div className="modalContainer">
                <FontAwesomeIcon icon={faAngleLeft} onClick={()=> changingIndex > 0 && setIndex(changingIndex-1)} className='faAngle'></FontAwesomeIcon>
                <img 
                className="modal-img"
                src={require(`../assets/small/${category}/${changingIndex}.jpg`)} alt ={name} 
                key={name}
                />

                <FontAwesomeIcon icon={faAngleRight} onClick={()=> changingIndex < currentArtwork.length - 1 && setIndex(changingIndex+1)} className='faAngle'></FontAwesomeIcon>
            </div>
            <div className='modal-img-info'>
                      <p>{currentArtwork[changingIndex].name}</p>
                      <p>{currentArtwork[changingIndex].medium}</p>
                      <p>{currentArtwork[changingIndex].size} {currentArtwork[changingIndex].price}</p>
            </div>
        </div>
    );
}

export default Modal;