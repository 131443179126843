import React from "react";
import coverImage from "../assets/cover/cover-image.jpg";
import Footer from "./Footer";

const Statement = ({isMobile})=>{
    return(
        <section className = "my-5 statement">
            <div className={`${!isMobile && 'flex'} statement-container`}>
            <div className="statement-img-container">
            <img src={coverImage} className={`${!isMobile && 'max-50 coverImage-margin'} cover-img`} alt="cover" />
            </div>
            <br></br>
            <div className="statement-p-container">
                <p>Nature holds limitless wonder and abstraction. I am fascinated by the patterns on water that occur when light and shadow hit the surface.</p>
                <br></br>
                <p>To capture the essence of how nature feels is always a goal and it takes me deep within myself.</p>
                <br></br>
                <p>As a painter, my goal is to create freely with no restraint. I try to work fast, intuitively choosing colors and creating an environment that feels right in the moment. Knowing that every color choice and brushstroke changes the composition, I enjoy answering to my impulses.</p>
                <br></br>
            <p>The best part about painting is the freedom I feel.   When I am having a good exchange with my work I am focused, free, the music is up, and I’m dancing!</p>
            </div>
        </div>
        </section>
    )
}

export default Statement;