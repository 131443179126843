import React, { useState } from 'react';

function Footer ({aboutSelected, isLandingPage}) {
    return (
        
        <footer className={`${aboutSelected && 'about-footer'} ${isLandingPage && 'position-absolute'} ${isLandingPage && 'about-footer'}`}>
            
            <p>Lynn Carlin</p>
            <p
            className='pointer footer-email' 
            onClick = {() => window.open('mailto:artistnarcata@gmail.com')}
            >artistnarcata@gmail.com</p>
            <p className='AidanMeyer'> Website by Aidan Meyer</p>
        </footer>
    )
}

export default Footer;